import data from '../data/parts'

export default data;

export const imageKeys = () => {
    // tidy this up
    let refs:Array<String> = [];
    data.forEach((part:any) => {
      part.products.forEach((product:any) => {
        product.finishes.forEach((finish:any) => {
          refs = [...refs, finish.image];
        })
      })
    })

    return refs
}