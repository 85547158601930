import React from 'react'
//import { NavLink } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import Button from '../button/medium'
import { useLocalStorage } from '../../../utils/custom-hooks'


import { RefreshIcon } from '@heroicons/react/outline'

import Canvas from './canvas'
import Selector from './selector'
import FinishSelector from './finishes'

import { Part } from '../../../utils/types'

import './configurator.css'
import './dark-mode-toggle.css'

type ConfiguratorProps = {
    parts: Array<Part>,
    images: Array<Object>
}

const Configurator : React.FC<ConfiguratorProps> = ({ parts, images, ...props }) => {
    
    const { watch, register } = useForm();

    let darkMode = watch('dark')


    const [ submission, setSubmission ] = useLocalStorage('configurator', {
        newel: null,
        endcap: null,
        handrail: null,
        baserail: null,
        glass: null,
        string: null
    });

    const [ spindleSetup, setSpindleSetup ] = useLocalStorage('spindles', {
        spindles: new Array(2),
        ratio: 'off'
    });

    return (
        
        <div className={`configurator ${darkMode ? 'dark' : 'light'}`}>
            <div className="configurator__dark-mode-toggle">
                <input type="checkbox" {...register('dark')} id="dark" defaultChecked={darkMode === true} />
                <label className="flex items-center text-xs uppercase text-white" htmlFor={'dark'}><span>Light</span>/<span>Dark</span><span className="toggle" /></label>
            </div>

        <form className="configurator__selector">
            <Selector 
                register={register}
                parts={parts}
                submission={submission}
                setSubmission={setSubmission}
                spindleSetup={spindleSetup}
                setSpindleSetup={setSpindleSetup}
            />

            <FinishSelector 
                parts={parts}
                submission={submission}
                setSubmission={setSubmission}
                spindleSetup={spindleSetup}
                setSpindleSetup={setSpindleSetup}
            />

        </form>
        <output className="configurator__canvas">
            <Canvas 
                submission={submission}
                spindleSetup={spindleSetup}
            />

            <div className="absolute bottom-16 left-1/2 -translate-x-1/2">
            <Button type="button" onClick={() => {
                setSubmission({
                    newel: null,
                    endcap: null,
                    handrail: null,
                    baserail: null,
                    glass: null,
                    string: null
                })
                setSpindleSetup({
                    spindles: [],
                    ratio: 'off'
                })
            }} disabled={false}><RefreshIcon />Start configurator again</Button>
            </div>
        </output>
    </div>
    )
}

export default Configurator