import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { useLocalStorage } from '../../utils/custom-hooks'
import { CloudDownloadIcon } from '@heroicons/react/outline'
import { useIndexedDBStore } from "use-indexeddb";

import Button from '../components/button/large'
import * as AssetManager from '../../utils/asset-manager'

import '../components/setup.css'

type SetupSceneProps = {
    online: Boolean,
    //onDownload: MouseEventHandler,
   // status: String
}

const Scene: React.FC<SetupSceneProps> = ({ online }) => {
    const [ status, setStatus ] = useState<String>('')
    const [ token, setToken ] = useLocalStorage(`token__${process.env.REACT_APP_VERSION}`, null);

    const navigate = useNavigate();

    const { update } = useIndexedDBStore("images");

    /*getAll().then(images => {
        if(images.length) {
            navigate(`/${process.env.REACT_APP_VERSION}/configurator`)
        }
    })*/

    useEffect(() => {
      if(token) {
        navigate(`/${process.env.REACT_APP_VERSION}/configurator`)
      }
    }, [token]);

    const onDownload = async () => {
        await AssetManager.setup({
            emitter: (state:string) => setStatus(state),
            update
          }).then(result => {
            setToken(process.env.REACT_APP_VERSION);
            navigate(`/${process.env.REACT_APP_VERSION}/configurator`)
          })
        }

   
    return (
        <>
        
        <div className={[process.env.NODE_ENV === 'production' ? 'hidden app:block' : '', 'mx-auto text-center text-white flex flex-col gap-y-4 items-center text-xl md:text-2xl w-full max-w-md antialiased mx-auto'].join(' ')}>
          <CloudDownloadIcon className="w-12 h-12 text-white" />Download{status !== '' ? 'ing' : ''} staircase assets
              
          {status === '' && <small className="block leading-normal text-base mb-6">Before you start using the app, we'll need to download a copy of all the parts and finishes. This may take 5-10 minutes depending on your connection speed.</small>}
          {status === '' && (
            <Button 
                onClick={onDownload} 
                disabled={status !== ''}
                //disabled={online === false || status !== ''}
                type="button">Download assets</Button>
          )}
          {status !== '' && (
            <ul className="setup">
              <li className={status === '1' ? 'is-active' : ''}>Fetching Opus / Stanza images</li>
              <li className={status === '2' ? 'is-active' : ''}>Fetching Newel images</li>
              <li className={status === '3' ? 'is-active' : ''}>Fetching Newel Cap images</li>
              <li className={status === '4' ? 'is-active' : ''}>Fetching Spindle images</li>
              <li className={status === '5' ? 'is-active' : ''}>Fetching Glass images</li>
              <li className={status === '6' ? 'is-active' : ''}>Fetching Baserail images</li>
              <li className={status === '7' ? 'is-active' : ''}>Fetching Handrail images</li>
              <li className={status === '8' ? 'is-active' : ''}>Fetching Frame images</li>
              <li className={status === '9' ? 'is-active' : ''}>Fetching String images</li>
            </ul>
          )}
            {online === false && <small className="text-xs text-neutral-300">Please connect to internet to download assets</small>}
        </div>
        </>
    )
}
export default Scene
