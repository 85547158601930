import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
//import { useForm } from 'react-hook-form'
import { useLocalStorage } from '../../utils/custom-hooks'
import Header from '../components/nav/header'
import { useIndexedDBStore } from "use-indexeddb";

import { Part, Product, Finish, Nullable } from '../../utils/types'

import { ChevronRightIcon } from '@heroicons/react/outline'

type ProductSceneProps = {
    config: Object,
    parts: Array<Part>
}

type Selection = {
    part: string,
    product: string,
    finish: Nullable<string>
}

const Scene: React.FC<ProductSceneProps> = ({ parts, config }) => {
    //const { register, watch } = useForm();
    const [ token ] = useLocalStorage(`token__${process.env.REACT_APP_VERSION}`, null);
    const [ selection, setSelection ] = useState<Selection>();

    const navigate = useNavigate();
    

    const { getByID } = useIndexedDBStore("images");

    useEffect(() => {
        if(token !== process.env.REACT_APP_VERSION) {
            navigate('/')
        }
    }, [token, navigate]);

    return (
        <div className="bg-silver overflow-hidden h-screen grid grid-cols-1 justify-start w-full" style={{gridTemplateRows: 'auto 1fr'}}>

            <Helmet title="Products" defer={false}>
            </Helmet>

            <Header active={'products'} />

            <div className="canvas grid grid-cols-12 gap-6">
                
                <nav className="col-span-12 flex-1 md:col-span-4 md:row-start-1  ">
                <ul className="border overflow-auto" style={{ height: `calc(100vh - 64px)`}}>
                    {parts.map((part:Part) => (
                        <li>
                            <h3 className="px-6 w-full text-left font-semibold py-2 leading-none text-lg">{part.name}</h3>
                            <ul className="divide-y">
                                {part.products.map((product:Product) => (
                                    <li>
                                        <button 
                                            className="px-6 flex items-center justify-between py-4 w-full text-left leading-none text-base bg-white" 
                                            type="button"
                                            onClick={async () => {
                                                let finish = product?.finishes.find((finish:Finish) => finish) || null;
                                                if(finish) {
                                                    setSelection({
                                                        part: part.name,
                                                        product: product.name,
                                                        finish: await getByID(finish.image).then((result:any) => result.blob)
                                                    })
                                                }
                                            }}
                                            >
                                            {product.name}
                                            <ChevronRightIcon className="w-5 h-5 text-neutral-400" />
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </li>
                    ))}

                </ul>
                </nav>
            

                {selection?.finish && (
                    <figure className="col-span-12 md:col-span-8 md:row-start-1 bg-transparent p-8 md:py-16 relative">
                        <img className="mx-auto align-top h-auto w-auto" src={selection.finish} alt={selection?.product} width={450} height={450} style={{objectFit: 'contain',maxHeight: '75vh', }} />
                        <figcaption className="block antialiased absolute top-24 left-16 text-left text-sm">
                            <small className="block text-lg font-light text-neutral-400">{selection?.part}</small>
                            <big className="block text-2xl text-black font-light">{selection?.product}</big>
                        </figcaption>
                    </figure>
                )}
            </div>
        
        </div>
    )
}
export default Scene