import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import { useLocalStorage } from '../../utils/custom-hooks'
import Header from '../components/nav/header'

import Configurator from '../components/configurator'

import { Part } from '../../utils/types'


type ConfiguratorSceneProps = {
    parts: Array<Part>,
    config: {
        images?: Array<Object>
    }
}

const Scene: React.FC<ConfiguratorSceneProps> = ({ parts, ...props }) => {
    const [ images ] = useState<Array<Object>>([]);
    const [ token ] = useLocalStorage(`token__${process.env.REACT_APP_VERSION}`, null);

    const navigate = useNavigate();

    useEffect(() => {
        if(token !== process.env.REACT_APP_VERSION) {
            navigate('/')
        }
    }, [token]);

   
    return (
        <div className="bg-silver overflow-hidden h-screen grid grid-cols-1 justify-start w-full" style={{gridTemplateRows: 'auto 1fr'}}>

            <Helmet title="Configurator" defer={false}>
            </Helmet>

            <Header active={'configurator'} />


            {parts && <Configurator parts={parts} images={images} />}

        
        </div>
    )
}
export default Scene