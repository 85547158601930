import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper'

import { Part, Product, Submission, SpindleSetup } from '../../../utils/types'
//import { chunk } from 'lodash'

import { useIndexedDBStore } from "use-indexeddb";

import 'swiper/css';
import 'swiper/css/pagination';

import './selector.css'

type SelectorProps = {
    register: Function,
    parts: Array<Part>,
    submission: Submission,
    setSubmission: Function,
    spindleSetup: SpindleSetup,
    setSpindleSetup: Function
}


const Selector : React.FC<SelectorProps> = ({ parts, register, submission, setSubmission, spindleSetup, setSpindleSetup, ...props }) => {
    const [ part, setPart ] = useState<string>('opus');
    //const [ product, setProduct ] = useState<Product>();
    const [ productsWithImages, setProductsWithImages ] = useState<Array<Object>>();

    const { getByID } = useIndexedDBStore('images')

    const products = parts.find(({ id }) => id === part)?.products;

    useEffect(() => {
        if(products && getByID) {
            Promise.all(products.map(async (product:Product) => ({
                ...product,
                blob: await getByID(product?.finishes[0].image).then((image:any) => image.blob).catch(() => null)
            }))).then(result => {
                setProductsWithImages(result)
            })
        }
    }, [products, getByID])

    useEffect(() => {
        if(submission.opus || submission.glass) {
            setSpindleSetup({
                ratio: 'off',
                spindles: []
            })
        }
        if(submission.opus) {
            setSubmission({
                ...submission,
                glass: null
            })
        }
        if(submission.glass) {
            setSubmission({
                ...submission,
                opus: null
            })
        }

    }, [submission.opus, submission.glass])

    
    return (
        <div className="configurator__selector-container">
            <nav>
                <button type="button" disabled={'opus' === part} onClick={() => setPart('opus')}>Opus / Stanza</button>
                <button type="button" disabled={'newel' === part} onClick={() => setPart('newel')}>Newels</button>
                <button type="button" disabled={'endcap' === part} onClick={() => setPart('endcap')}>Newel caps</button>
                <button type="button" disabled={'handrail' === part} onClick={() => setPart('handrail')}>Handrails</button>
                <button type="button" disabled={'glass' === part} onClick={() => setPart('glass')}>Glass</button>
                <div className="spindle-selector">
                    <button type="button" disabled={'spindle' === part} onClick={() => setPart('spindle')}>Spindles</button>
                    <div>
                        <label htmlFor="mix">Mix</label>
                        <select 
                            name="mix" 
                            id="mix" 
                            defaultValue={spindleSetup?.ratio} 
                            onChange={({ target: { value } }) => setSpindleSetup({...spindleSetup, ratio: value})}
                        >
                            <option value="off">Off</option>
                            <option value="1">1:1</option>
                            <option value="2">2:1</option>
                            <option value="3">3:1</option>
                        </select>
                    </div>
                </div>
                

                {/*parts.map((p:Part) => (
                    <button 
                        type="button" 
                        key={p.id} 
                        disabled={p.id === part} 
                        onClick={() => setPart(p.id)}>{p.name}</button>
                ))*/}
            </nav>
        <fieldset>
        <Swiper
            className="slides"
            spaceBetween={0}
            initialSlide={1}
            modules={[Pagination]}
            pagination={{ clickable: false }}
            slidesPerView={3}
            slidesPerGroup={3}
        >
            {part !== 'spindle' && productsWithImages && productsWithImages.map((p:any) => (
                <SwiperSlide key={p.name}>
                    <button 
                        disabled={p.name === submission[part as keyof Submission]?.name} 
                        type="button" 
                        onClick={() => setSubmission({...submission, [part]: { name: p.name, finish: null, blob: null, size: p.width || null } } )}
                    >
                        <img src={p.blob} alt={p.name} />
                        <strong className="block text-black text-sm leading-5">{p.name}</strong>
                    </button>
                </SwiperSlide>
            ))}
            {part === 'spindle' && productsWithImages && productsWithImages.map((p:any) => (
                <SwiperSlide key={p.name}>
                    <button 
                        type="button"
                        disabled={spindleSetup.spindles.find(o => o?.name === p.name) ? true : false}
                        onClick={() => {
                            let clone = [...spindleSetup.spindles];
                            if(spindleSetup.ratio === 'off') {
                                setSpindleSetup({...spindleSetup, spindles: [{
                                    name: p.name,
                                    finish: null,
                                    blob: null
                                }]})
                            }
                            else {
                                clone = [...clone, {
                                    name: p.name,
                                    finish: null,
                                    blob: null
                                }]
                                setSpindleSetup({...spindleSetup, spindles: [ clone.at(0), clone.at(-1) ] })
                            }

                            setSubmission({...submission, ...{
                                opus: null,
                                glass: null
                            }})
                            
                        }
                    }>
                        <img src={p.blob} alt={p.name} />
                        <strong className="block text-black text-sm leading-5">{p.name}</strong>
                    </button>
                </SwiperSlide>
            ))}
        </Swiper>
        </fieldset>
        </div>
    )
}

export default Selector