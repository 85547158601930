import React from 'react'
import { NavLink } from 'react-router-dom'

import logo from '../../../logo.png'

import './header.css'

type HeaderProps = {
    active: String
}

const Header : React.FC<HeaderProps> = ({ active, ...props }) => {
    

    return (
        <header className="header " {...props}>
            <img src={logo} alt='Neville Johnson' className="mr-auto" />
            <NavLink className={active === 'configurator' ? 'opacity-100' : 'opacity-50'} to={`/${process.env.REACT_APP_VERSION}/configurator`}>Configurator</NavLink>
            <NavLink className={active === 'products' ? 'opacity-100' : 'opacity-50'} to={`/${process.env.REACT_APP_VERSION}/products`}>Products</NavLink>
        </header>
    )
}

export default Header