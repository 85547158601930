import React, { useState, useEffect } from 'react';
import { 
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";

import Products from './0.1/scenes/products'
import Configurator from './0.1/scenes/configurator'

import parts from './utils/data-manager'
//import { useLocalStorage } from './utils/custom-hooks';
import Setup from './0.1/scenes/setup'
import { PlusIcon } from '@heroicons/react/solid'

//import logo from './logo.svg';

//import Button from './0.1/components/button/large'

//import './webfonts.css';


function App() {
  const [ online, setOnline ] = useState<Boolean>(false);
  const [ appConfig ] = useState<Object>({});


  useEffect(() => {
    setOnline(window.navigator.onLine);
    window.addEventListener('offline', function(e) { setOnline(false); });
    window.addEventListener('online', function(e) { setOnline(true); });
  }, []);

  /*useEffect(() => {
    const stored_count = getAll().then(records => {
      const image_keys = imageKeys()
      const image_count = image_keys.length;

      if(records.length === 0) {
        // first time loading the app - get everything
      }


    });    

  }, [online]);*/

  return (
    <div className="mx-auto bg-black h-screen flex justify-center items-center gap-y-10 flex-col relative">
<div className={[process.env.NODE_ENV === 'development' ? 'hidden' : '', `app:hidden mx-auto text-center text-white flex flex-col gap-y-4 items-center text-xl md:text-2xl w-full max-w-md antialiased mx-auto`].join(' ')}>
        <PlusIcon className="w-12 h-12 text-white border border-white border-2 rounded" />
        Add to homescreen to install
      </div>
      <div className={[process.env.NODE_ENV === 'production' ? 'hidden app:block' : '', `w-full`].join(' ')}>
      <BrowserRouter>
        <Routes>
          <Route path={`${process.env.REACT_APP_VERSION}/products`} element={<Products parts={parts} config={appConfig} />} />
          <Route path={`${process.env.REACT_APP_VERSION}/configurator`} element={<Configurator parts={parts} config={appConfig} />} />
          <Route path={`*`} element={
            <Setup online={online}  />
          } />
        </Routes>
      </BrowserRouter>
      </div>
    </div>
  );
}

export default App;