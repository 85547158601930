import React, { MouseEventHandler, ReactNode } from 'react'

import './large.css'

type ButtonProps = {
    children: ReactNode,
    disabled: boolean,
    type: String,
    onClick:  MouseEventHandler
}

const Button : React.FC<ButtonProps> = ({ children, onClick, type, ...props }) => (
    <button onClick={onClick} className="button button--large" {...props}>{children}</button>
)

export default Button