import React from 'react';
//import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import IndexedDBProvider from "use-indexeddb";
import './webfonts.css';
import './tailwind.css';


// Database Configuration
const idbConfig = {
  databaseName: "staircase-configurator",
  version: 1,
  stores: [
    {
      name: "images",
      id: { keyPath: "key", autoIncrement: false },
      indices: [
        { name: "blob", keyPath: "blob" },
        { name: "part", keyPath: "part" },
      ],
    },
  ],
};


const rootElement = document.getElementById('root');

// 👇️ non-null (!) assertion
const root = createRoot(rootElement!);

root.render(
  <React.StrictMode>
    <IndexedDBProvider config={idbConfig}>
    <App />
    </IndexedDBProvider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
const configuration = {
  onUpdate: (registration:any) => {
    if (registration && registration.waiting) {
      if (window.confirm('New version available! refresh to update your app?')) {
        registration.waiting.postMessage({ type: 'SKIP_WAITING' });
        window.location.reload();
      }
    }
  }
 };


serviceWorkerRegistration.register(configuration);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
