import React from 'react'
//import { NavLink } from 'react-router-dom'
//import { useForm } from 'react-hook-form'
//import guide from '../../../assets/guide.png';


import newel from '../../../assets/defaults/newels/default/default.png';
import string from '../../../assets/defaults/strings/default-string.png';
import spindle from '../../../assets/defaults/spindles/default/default.png';
import baserail from '../../../assets/defaults/baserails/default.png';
import endcap from '../../../assets/defaults/newel-caps/default/default.png';
import handrail from '../../../assets/defaults/handrails/default.png';

import { SpindleSetup, Submission } from '../../../utils/types'

import './canvas.css'

type CanvasProps = {
    submission: Submission,
    spindleSetup: SpindleSetup
}

const Canvas : React.FC<CanvasProps> = ({ submission, spindleSetup, ...props }) => {
    
    let z = 0;

    const glassSize = calculateGlassSize(submission.glass?.size)

    return (
        
        <div className="configurator__canvas-display">
            {/*<img className="w-full h-auto align-top" src={guide} width={1224} height={1006} />
          
            <img className="part newel" src={newel} width={58} height={392} />
            <img className="part newel" src={newel} width={58} height={392} />
    <img className="part string" src={string} width={792} height={565} />*/}

            <svg className="w-full h-auto" width="1087" height="893" viewBox="0 0 1087 893" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <g id="Artboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    
                    <g id="newel-caps">
                        <image href={submission?.endcap?.blob || endcap} preserveAspectRatio="xMidYMin slice" x={1012} y={-2} width={76} />
                        <image href={submission?.endcap?.blob || endcap} preserveAspectRatio="xMidYMin slice" x={0} y={349} width={76} />
                    </g>
                    {!submission.opus?.finish && !submission.glass?.finish && (
                    <g id="spindles">
                        {Array.from({ length: 25 }, (i, o) => {
                            let img = spindle;
                            if(spindleSetup.spindles.length > 0) {
                                img = spindleSetup.spindles[0]?.blob || spindle
                            }
                            if(spindleSetup.ratio !== 'off'){
                                //console.log(o % (parseInt(spindleSetup.ratio) + 1))
                                if(parseInt(spindleSetup.ratio) === z) { 
                                    z = 0;
                                    img = spindleSetup.spindles[1]?.blob || spindle
                                }
                                else {
                                    z = z + 1;
                                }
                            }
                            return <image key={o} href={img} preserveAspectRatio="xMidYMin slice" x={80 + (o * 42)} y={426 - (o * 15)} width={16} height={268} />
                        })}
                    </g>
                    )}
                    {submission.opus?.blob && (
                    <g id="opus">
                        <image href={submission.opus.blob} preserveAspectRatio="xMidYMin slice" width={458} x={70} y={275} />
                        <image href={submission.opus.blob} preserveAspectRatio="xMidYMin slice" width={458} x={552} y={104} />
                    </g>
                    )}
                    {submission.glass?.blob && glassSize && (
                        <g id="glass">
                            {glassSize === 1 && <image href={submission.glass.blob} preserveAspectRatio="xMidYMin slice" width={962} x={62} y={106} />}
                            {glassSize === 2 && (
                                <>
                                    <image href={submission.glass.blob} preserveAspectRatio="xMidYMin slice" width={458} x={70} y={275} />
                                    <image href={submission.glass.blob} preserveAspectRatio="xMidYMin slice" width={458} x={552} y={104} />
                                </>
                            )}
                            {glassSize === 12 && Array.from({ length: glassSize }, (i, o) => (
                                <image href={submission.glass.blob?.toString()} preserveAspectRatio="xMidYMin slice" width={52} x={70 + (o * 81)} y={424 - (o * 29)} height={272} />
                            ))}
                            {glassSize === 16 && Array.from({ length: glassSize }, (i, o) => (
                                <image href={submission.glass.blob?.toString()} preserveAspectRatio="xMidYMin slice" width={40} x={66 + (o * 61)} y={424 - (o * 21)} height={275} />
                            ))}
                        </g>
                    )}
                    <g id="string">
                        <image href={submission?.string?.blob || string} preserveAspectRatio="xMidYMin slice" x={50} y={77} width={990}  />
                    </g>
                    <g id="baserail">
                        <image href={submission?.baserail?.blob || baserail} preserveAspectRatio="xMidYMin slice" x={50} y={80} width={990}  />
                    </g>
                    <g id="handrail">
                        <image href={submission?.handrail?.blob || handrail} preserveAspectRatio="xMidYMin slice" x={50} y={70} width={990}  />
                    </g>
                    <g id="newels">
                        <image href={submission?.newel?.blob || newel} preserveAspectRatio="xMidYMin slice" x="1034" y="53" width={32} height={489} />
                        <image href={submission?.newel?.blob || newel} preserveAspectRatio="xMidYMin slice" x="21" y="404" width={32} height={489} />
                        
                    </g>
                </g>
            </svg>
        </div>
    )
}

export default Canvas

const calculateGlassSize = (size:any) => {
    if(!size) {
        return null;
    }
    let n = parseInt(size)
    let o = 16;
    
    switch(n) {
        case 50 :
            o = 12;
        break;
        case 398 :
        case 399 :
            o = 2;
        break;
        case 800 :
            o = 1;
        break;
    }

    return o;
}