import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Part, Submission, SpindleSetup } from '../../../utils/types'

import { useIndexedDBStore } from "use-indexeddb";
import './finishes.css'

type CanvasProps = {
    parts: Array<Part>,
    submission: Submission,
    setSubmission: Function,
    spindleSetup: SpindleSetup,
    setSpindleSetup: Function
}

const Finishes : React.FC<CanvasProps> = ({ parts, submission, setSubmission, spindleSetup, setSpindleSetup, ...props }) => {
    
    const { watch, register } = useForm();

    const { getByID } = useIndexedDBStore('images')

    const newelField = watch('newel', submission?.newel?.finish)
    const endcapField = watch('endcap', submission?.endcap?.finish)
    const handrailField = watch('handrail', submission?.handrail?.finish)
    const spindlesField1 = watch('spindle1', spindleSetup.spindles[0]?.finish);
    const spindlesField2 = watch('spindle2', spindleSetup.spindles[1]?.finish);
    const baserailField = watch('baserail', submission?.baserail?.finish)
    const stringField = watch('string', submission?.string?.finish)
    const opusField = watch('opus', submission?.opus?.finish)
    const glassField = watch('glass', submission?.glass?.finish)

    useEffect(() => {
        if(endcapField) {
            getByID(endcapField).then((result:any) => {
                setSubmission({...submission, endcap: { ...submission.endcap, finish: result.key, blob: result.blob }})
            })
        }
    }, [ endcapField ])

    useEffect(() => {
        if(newelField) {
            console.log({ newelField })
            getByID(newelField).then((result:any) => {
                setSubmission({...submission, newel: { ...submission.newel, finish: result.key, blob: result.blob }})
                //setSubmission({...submission, newel: { ...submission.newel, finish: result.key, blob: null }})
            })
        }
    }, [newelField])

    useEffect(() => {
        if(handrailField) {
            getByID(handrailField).then((result:any) => {
                setSubmission({...submission, handrail: { ...submission.handrail, finish: result.key, blob: result.blob }})
                //setSubmission({...submission, handrail: { ...submission.handrail, finish: result.key, blob: null }})
            })
        }
    }, [handrailField])


    useEffect(() => {
        if(baserailField) {
            getByID(baserailField).then((result:any) => {
                setSubmission({...submission, baserail: { ...submission.baserail, finish: result.key, blob: result.blob }})
            })
        }
    }, [baserailField])
    
    useEffect(() => {
        if(stringField) {
            getByID(stringField).then((result:any) => {
                setSubmission({...submission, string: { ...submission.string, finish: result.key, blob: result.blob }})
            })
        }
    }, [ stringField])

    useEffect(() => {
        if(opusField) {
            getByID(opusField).then((result:any) => {
                setSubmission({...submission, opus: { ...submission.opus, finish: result.key, blob: result.blob }})
            })
        }
    }, [ opusField])

    useEffect(() => {
        if(glassField) {
            getByID(glassField).then((result:any) => {
                setSubmission({...submission, glass: { ...submission.glass, finish: result.key, blob: result.blob }})
            })
        }
    }, [ glassField ])


    useEffect(() => {
        if(spindlesField1) {
            getByID(spindlesField1).then((result:any) => {
                let clone = [...spindleSetup.spindles];
                clone[0] = {
                    name: clone[0].name,
                    finish: result.key,
                    blob: result.blob
                }
                setSpindleSetup({...spindleSetup, spindles: clone })
            })
        }
    }, [ spindlesField1 ])
   
    useEffect(() => {

        if(spindlesField2) {
            getByID(spindlesField2).then((result:any) => {
                let clone = [...spindleSetup.spindles];
                clone[1] = {
                    name: clone[1].name,
                    finish: result.key,
                    blob: result.blob
                }
                setSpindleSetup({...spindleSetup, spindles: clone })
            })
        }
    }, [spindlesField2]);
   
    return (
        
        <div className="configurator__finish-selector">
            <ul>
                <li>
                    <select 
                        disabled={submission?.newel === null} 
                        {...register('newel')} 
                        id="option-newel"
                        value={newelField}
                    >
                        <option value="">Choose your finish</option>
                        {parts.find(({ id }) => id === 'newel')?.products.find(({ name }) => name === submission?.newel?.name)?.finishes.map(finish => (
                            <option key={finish.name} value={finish.image}>{finish.name}</option>
                        ))}
                    </select>
                    <label htmlFor="option-newel">Newel: {submission?.newel?.name || 'none selected'}</label>
                </li>
                <li>
                    <select
                        disabled={submission?.endcap === null} 
                        {...register('endcap')} 
                        id="option-endcap"
                        value={endcapField}
                    >
                        <option value="">Choose your finish</option>
                        {parts.find(({ id }) => id === 'endcap')?.products.find(({ name }) => name === submission?.endcap?.name)?.finishes.map(finish => (
                            <option key={finish.name} value={finish.image}>{finish.name}</option>
                        ))}
                    </select>
                    <label htmlFor="option-endcap">Newel Cap: {submission?.endcap?.name || 'none selected'}</label>
                </li>
                {submission.opus === null && submission.glass === null && (<li>
                    <select 
                        disabled={!spindleSetup.spindles[0]}
                        {...register('spindle1')} 
                        id="option-spindle-1"
                        value={spindlesField1}
                    >
                        <option value="">Choose your finish</option>
                        {parts.find(({ id }) => id === 'spindle')?.products.find(({ name }) => {
                            return name === spindleSetup.spindles[0]?.name
                        })?.finishes.map(finish => (
                            <option key={finish.name} value={finish.image}>{finish.name}</option>
                        ))}
                    </select>
                    <label htmlFor="option-spindle-1">Spindle: {spindleSetup.spindles[0]?.name || 'none selected'}</label>
                </li>)}
                {submission.opus && (
                    <li>
                        <select
                            {...register('opus')} 
                            id="option-opus"
                            value={opusField}
                        >
                            <option value="">Choose your finish</option>
                            {parts.find(({ id }) => id === 'opus')?.products.find(({ name }) => name === submission?.opus?.name)?.finishes.map(finish => (
                                <option key={finish.name} value={finish.image}>{finish.name}</option>
                            ))}
                        </select>
                        <label htmlFor="option-opus">{submission?.opus?.name || 'none selected'}</label>
                    </li>
                )}
                {submission.glass && (
                    <li>
                        <select
                            {...register('glass')} 
                            id="option-glass"
                            value={glassField}
                        >
                            <option value="">Choose your finish</option>
                            {parts.find(({ id }) => id === 'glass')?.products.find(({ name }) => name === submission?.glass?.name)?.finishes.map(finish => (
                                <option key={finish.name} value={finish.image}>{finish.name}</option>
                            ))}
                        </select>
                        <label htmlFor="option-glass">{submission?.glass?.name || 'none selected'}</label>
                    </li>
                )}
                {spindleSetup.ratio !== 'off' && spindleSetup.spindles[1] && (
                    <li>
                        <select 
                            disabled={spindleSetup.spindles[1] === null}
                            {...register('spindle2')} 
                            id="option-spindle-2"
                            value={spindlesField2}
                        >
                            <option value="">Choose your finish</option>
                            {parts.find(({ id }) => id === 'spindle')?.products.find(({ name }) => {
                                return name === spindleSetup.spindles[1]?.name
                            })?.finishes.map(finish => (
                                <option key={finish.name} value={finish.image}>{finish.name}</option>
                            ))}
                        </select>
                        <label htmlFor="option-spindle-2">Spindle: {spindleSetup.spindles[1]?.name || 'none selected'}</label>
                    </li>
                )}
                <li>
                    <select 
                        disabled={submission?.handrail === null} 
                        {...register('handrail')} 
                        id="option-handrail"
                        value={handrailField}
                    >
                        <option value="">Choose your finish</option>
                        {parts.find(({ id }) => id === 'handrail')?.products.find(({ name }) => name === submission?.handrail?.name)?.finishes.map(finish => (
                            <option key={finish.name} value={finish.image}>{finish.name}</option>
                        ))}
                        
                    </select>
                    <label htmlFor="option-handrail">Handrail: {submission?.handrail?.name || 'none selected'}</label>
                </li>
                <li>
                    <label htmlFor="option-baserail">Baserail</label>
                    <select 
                        {...register('baserail')} 
                        id="option-baserail"
                        value={baserailField}
                        >
                        <option value="">Choose your finish</option>
                        {parts.find(({ id }) => id === 'baserail')?.products[0]?.finishes.map(finish => (
                            <option key={finish.name} value={finish.image}>{finish.name}</option>
                        ))}
                    </select>
                </li>
                <li>
                    <label htmlFor="option-string">String</label>
                    <select 
                        {...register('string')} 
                        id="option-string"
                        value={stringField}
                    >
                        <option value="">Choose your finish</option>
                        {parts.find(({ id }) => id === 'string')?.products[0]?.finishes.map(finish => (
                            <option key={finish.name} value={finish.image}>{finish.name}</option>
                        ))}
                    </select>
                </li>
            </ul>
        </div>
    )
}

export default Finishes