

type SetupProps = {
    emitter: Function,
    update: Function
}

type Part = {
    name: String,
    id: String,
    products: Array<Product>
}

type Product = {
    alternateImage: String,
    name: String,
    width: String,
    finishes: Array<Finish>
}

type Finish = {
    name: String,
    image: String
}

export const setup = async (settings:SetupProps) => {

    const parts_set = await fetch('/parts.json', {
        method: 'GET',
        headers: new Headers({
          'content-type': 'application/json'
        })
      })
      .then(res => res.json())

      //console.log(parts_set)


    let refs:Array<String> = [];
    settings.emitter('fetching-opus-parts');
    const opus = parts_set.find((part:Part) => part.id === 'opus');
    opus.products.forEach((product:Product) => {
        product.finishes.forEach((finish:Finish) => {
            refs = [...refs, finish.image];
        })
    })

    // now fetch and write images to databse
    settings.emitter('1');
    let result = [await fetchAndSync(refs, settings.update, 'opus')]


    refs = [];

    settings.emitter('fetching-newel-parts');
    const newel = parts_set.find((part:Part) => part.id === 'newel');
    newel.products.forEach((product:Product) => {
        product.finishes.forEach((finish:Finish) => {
            refs = [...refs, finish.image];
        })
    })
    settings.emitter('2');
    result = [...result, await fetchAndSync(refs, settings.update, 'newel')];


    refs = [];

    settings.emitter('fetching-endcap-parts');
    const endcap = parts_set.find((part:Part) => part.id === 'endcap');
    endcap.products.forEach((product:Product) => {
        product.finishes.forEach((finish:Finish) => {
            refs = [...refs, finish.image];
        })
    })
    settings.emitter('3');
    result = [...result, await fetchAndSync(refs, settings.update, 'endcap')];


    refs = [];

    settings.emitter('fetching-spindle-parts');

    const spindles = parts_set.find((part:Part) => part.id === 'spindle');
    spindles.products.forEach((product:Product) => {
        product.finishes.forEach((finish:Finish) => {
            refs = [...refs, finish.image];
        })
    })
    settings.emitter('4');
    result = [...result, await fetchAndSync(refs, settings.update, 'spindle')];


    refs = [];

    settings.emitter('fetching-glass-parts');
    const glass = parts_set.find((part:Part) => part.id === 'glass');
    glass.products.forEach((product:Product) => {
        product.finishes.forEach((finish:Finish) => {
            refs = [...refs, finish.image];
        })
    })
    settings.emitter('5');
    result = [...result, await fetchAndSync(refs, settings.update, 'glass')];


    refs = [];

    settings.emitter('fetching-baserails');
    const baserail = parts_set.find((part:Part) => part.id === 'baserail');
    baserail.products.forEach((product:Product) => {
        product.finishes.forEach((finish:Finish) => {
            refs = [...refs, finish.image];
        })
    })
    settings.emitter('6');
    result = [...result, await fetchAndSync(refs, settings.update, 'baserail')];


    refs = [];

    settings.emitter('fetching-handrails');
    const handrail = parts_set.find((part:Part) => part.id === 'handrail');
    handrail.products.forEach((product:Product) => {
        product.finishes.forEach((finish:Finish) => {
            refs = [...refs, finish.image];
        })
    })
    settings.emitter('7');
    result = [...result, await fetchAndSync(refs, settings.update, 'handrail')];

    
    refs = [];

    settings.emitter('fetching-frames');
    const frame = parts_set.find((part:Part) => part.id === 'frame');
    frame.products.forEach((product:Product) => {
        product.finishes.forEach((finish:Finish) => {
            refs = [...refs, finish.image];
        })
    })
    settings.emitter('8');
    result = [...result, await fetchAndSync(refs, settings.update, 'frame')];

    refs = [];

    settings.emitter('fetching-strings');
    const strings = parts_set.find((part:Part) => part.id === 'string');
    strings.products.forEach((product:Product) => {
        product.finishes.forEach((finish:Finish) => {
            refs = [...refs, finish.image];
        })
    })
    settings.emitter('9');
    result = [...result, await fetchAndSync(refs, settings.update, 'string')];

    settings.emitter('done');

    return result;
}

const fetchAndSync = async (image_refs:Array<String>, upsert:Function, part: string) => {

    //console.log('fetchAndSync %o', image_refs)

    // we need to slice these into sets

    const pairs = await Promise.allSettled(image_refs.map(async (key:String) => {
        try {
            const response = await fetch(`https://staircaseimages.blob.core.windows.net/staircase-configurator/${key}`)
            const blob = await response.blob()
            
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            return {
                key,
                part,
                blob: await new Promise((resolve, reject) => {
                    reader.onloadend = () => {
                    const base64data = reader.result;
                    resolve(base64data);
                    }
                })
            }
        }
        catch(e) {
            return {
                blob: '**', /* put default image base64 encoded here */
                part,
                key
            }
        }
      })).then(blobs => {
          return blobs.map((blob:any) => {
            return blob.value;
        })
      })

    
    return await Promise.all(pairs.map(async record => {
        return await new Promise((resolve, reject) => upsert(record).then((id:any) => resolve(id)));
    }))

}